import { useState } from 'react';

export const useMove = () => {
    const [state, setState] = useState({ x: 0, y: 0 });

    const handleMouseMove = e => {
        // e.persist();
        setState(state => ({ ...state, x: e.clientX, y: e.clientY }));
    };

    return {
        cursorX: state.x,
        cursorY: state.y,
        handleMouseMove,
    };
};
