import React, { useEffect } from 'react';
import { useMove } from '@hooks/useMove';

//Styles
import styles from './Cursor.module.scss';

//Images
import CursorSliderBlack from '@images/cursors/cursor-slider-black.svg';
import CursorSliderRed from '@images/cursors/cursor-slider-red.svg';
import CursorSliderRedVertical from '@images/cursors/cursor-slider-red-vertical.svg';
import CursorApply from '@images/cursors/cursor-apply.svg';
import CursorPause from '@images/cursors/pause-light.svg';
import CursorPlay from '@images/cursors/play-light.svg';

export const Cursor = props => {
    const { cursorActive, type } = props;
    const { cursorX, cursorY, handleMouseMove } = useMove();

    useEffect(() => {
        const mediaMatch = window.matchMedia('(min-width: 992px)');

        const toggleMouseMove = () => {
            if (mediaMatch.matches) {
                document.addEventListener('mousemove', handleMouseMove);
            } else {
                document.removeEventListener('mousemove', handleMouseMove);
            }
        };

        toggleMouseMove();
        window.addEventListener('resize', toggleMouseMove);

        return () => {
            window.removeEventListener('resize', toggleMouseMove);
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [handleMouseMove]);

    const className = `${styles.cursor} ${cursorActive ? styles.active : ''}`;

    const style = {
        transform: `translate3d(${cursorX}px, ${cursorY}px, 0)`,
    };

    switch (type) {
        case 'play':
            return <CursorPlay className={className} style={style} />;

        case 'pause':
            return <CursorPause className={className} style={style} />;

        case 'slider':
            return <CursorSliderBlack className={className} style={style} />;

        case 'slider-black':
            return <CursorSliderBlack className={className} style={style} />;

        case 'slider-red':
            return <CursorSliderRed className={className} style={style} />;

        case 'slider-red-vertical':
            return <CursorSliderRedVertical className={className} style={style} />;

        case 'apply':
            return <CursorApply className={className} style={style} />;

        default:
            return null;
    }
};
